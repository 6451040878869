import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Warehousing & Distribution</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Warehousing & Distribution</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="news-single section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="single-main">
                    {/* News Head */}
                    <div className="news-head">
                      <img src="img/Warehousing & Distribution.jpg" alt="#" />
                    </div>
                    {/* News Title */}
                    <h1 className="news-title">Warehousing & Distribution</h1>
                    {/* Meta */}

                    {/* News Text */}
                    <div className="news-text">
                      <p>
                        {companyname} provides comprehensive warehousing
                        solutions, backed by well-trained personnel and
                        top-notch management services.
                      </p>

                      <p>
                        Our robust infrastructure, guided by a scalable supply
                        chain tailored to your company's needs, is marked by
                        transparent and responsive procedures and services. Our
                        3PL (Third-Party Logistics) services enhance your
                        business strategy and encompass modern warehousing
                        facilities, skilled labor, and effective supply chain
                        management.
                      </p>

                      <p>Our Contract Logistics offerings include:</p>

                      <ul>
                        <li>End-to-end surveillance.</li>
                        <li>
                          Material handling for both standard and non-standard
                          cargo, ODC (Over-Dimensional Cargo) shipments,
                          perishables, and volatile goods.
                        </li>
                        <li>Asset-light model.</li>
                        <li>Customized storage solutions.</li>
                        <li>Flexible space and transportation options.</li>
                        <li>
                          MHE (Material Handling Equipment), HPT
                          (High-Performance Trucks), Forklifts, and Reach
                          Trucks.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="main-sidebar">
                {/* Single Widget */}
                <div className="single-widget category">
                  <h3 className="title">Other Services</h3>
                  <ul className="categor-list">
                    <li>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Surface">Surface Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </li>
                  </ul>
                </div>
                {/*/ End Single Widget */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
