import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Sea Freight Forwarding</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Sea Freight Forwarding</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="news-single section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="single-main">
                    {/* News Head */}
                    <div className="news-head">
                      <img src="img/Sea Freight Forwarding.jpg" alt="#" />
                    </div>
                    {/* News Title */}
                    <h1 className="news-title">Sea Freight Forwarding</h1>
                    {/* Meta */}

                    {/* News Text */}
                    <div className="news-text">
                      <p>
                        {companyname} has the capability to manage delicate
                        cargo, unconventional dimensions, and remote
                        destinations.
                      </p>

                      <p>
                        Our advanced solutions create a pathway to your sales
                        and procurement markets wherever your shipments may
                        require transport. We serve as a unified logistics hub
                        for all your goods traversing the supply chain, thanks
                        to our partnerships with numerous carriers.
                      </p>

                      <p>
                        We provide a comprehensive array of services, including
                        flexible and reliable quotation requests, shipping
                        schedules, purchase order management, and safeguarding
                        space during peak demand periods. Navigating shipping
                        challenges is made more manageable with our
                        comprehensive and personalized solutions.
                      </p>

                      <p>Our tailored services encompass:</p>

                      <ul>
                        <li>FCL and LCL consolidation.</li>
                        <li>
                          Multimodal transportation via sea, air, and road.
                        </li>
                        <li>Customs clearance and brokerage services.</li>
                        <li>Cargo insurance services.</li>
                        <li>
                          Pick-up, packing choices, delivery, and distribution.
                        </li>
                        <li>Comprehensive documentation service.</li>
                        <li>
                          Sensitive cargo handling –
                          Dry/Liquid/Bulk/Perishables.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="main-sidebar">
                {/* Single Widget */}
                <div className="single-widget category">
                  <h3 className="title">Other Services</h3>
                  <ul className="categor-list">
                    <li>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Surface">Surface Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </li>
                  </ul>
                </div>
                {/*/ End Single Widget */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
