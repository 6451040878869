import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Sea from "./pages/Sea";
import Air from "./pages/Air";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Whyus from "./pages/Whyus";
import Hazardous from "./pages/Hazardous";
import Warehousing from "./pages/Warehousing";
import Trading from "./pages/Trading";
import Courier from "./pages/Courier";
import Surface from "./pages/Surface";
import Careers from "./pages/Careers";


import Multimodal from "./pages/Multimodal";


export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Multimodal" element={<Multimodal />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Hazardous" element={<Hazardous />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Trading" element={<Trading />} />
          <Route path="/Courier" element={<Courier />} />
          <Route path="/Surface" element={<Surface />} />

    
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
