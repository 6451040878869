import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>About Us</h2>
                <ul className="bread-list">
                  <li><Link to="/">Home</Link></li>
                  <li><i className="icofont-simple-right" /></li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Start Schedule Area */}
            <section className="schedule">
        <div className="container">
          <div className="schedule-inner">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                {/* single-schedule */}
                <div className="single-schedule first">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-bullseye" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Mission</h4>
                      <p>
                        To provide seamless global logistics solutions to your
                        business, connecting businesses and markets with
                        efficiency and reliability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* single-schedule */}
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-eye" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Vision</h4>
                      <p>
                        To be the industry leader, setting new standards in
                        freight forwarding through innovation and excellence,
                        while prioritizing customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                {/* single-schedule */}
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-check" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Value</h4>
                      <p>
                        Our values of innovation and sustainability guide us in
                        providing top-notch logistics solutions that benefit
                        both our clients and the environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/End Start schedule Area */}

      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Navigating Trade Routes, Expanding Opportunities</h2>
                <img src="img/section-img.png" alt="#" />
                <p>Welcome to {companyname}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Who We Are</h3>
                <p>
                  Starting from modest beginnings, we've evolved into a market
                  leader, serving as the preferred logistics partner for various
                  industries, managing their inbound and outbound shipments
                  effectively.
                </p>

                <p>
                  Operating from our central office in Mumbai, we provide a
                  one-stop solution, enabling our clients to focus on their core
                  operations.
                </p>

                <p>
                  We offer a diverse range of hassle-free services tailored to
                  our clients' needs, including airfreight, seafreight,
                  warehousing, customs clearance, and transportation.
                  Additionally, we provide value-added services like cargo
                  insurance, packing, fumigation, palletization, and more, as
                  required.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div className="choose-right">
                <div className="video-image">
                  {/* Video Animation */}

                  {/*/ End Video Animation */}
                  <Link to="/About" className=" btn mfp-iframe">
                    Learn More
                  </Link>
                </div>
              </div>
              {/* End Choose Rights */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Why choose */} 

      <Footer />
    </>
  );
}
