import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Multimodal Transportation</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Multimodal Transportation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="news-single section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="single-main">
                    {/* News Head */}
                    <div className="news-head">
                      <img src="img/Multimodal Transportation.jpg" alt="#" />
                    </div>
                    {/* News Title */}
                    <h1 className="news-title">Multimodal Transportation</h1>
                    {/* Meta */}

                    {/* News Text */}
                    <div className="news-text">
                      <p>
                        When a single organization handles your transportation
                        needs, your company can achieve significant time and
                        cost savings. Leverage our extensive network and
                        expertise to access the optimal blend of multimodal
                        transportation.
                      </p>

                      <p>
                        Our versatile inland transportation services, catering
                        to both FCL (full container load) and LCL (less than
                        full container load) shipments via rail, barge, and
                        road, ensure timely and precise pick-up and delivery of
                        your goods.
                      </p>

                      <p>
                        Our expert team excels in selecting the most efficient
                        routes, managing paperwork, adhering to schedules, and
                        complying with legal regulations, creating new
                        opportunities for trade expansion.
                      </p>

                      <p>
                        For all your multimodal transportation requirements,
                        choose {companyname} as your integrated solution
                        services provider.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="main-sidebar">
                {/* Single Widget */}
                <div className="single-widget category">
                  <h3 className="title">Other Services</h3>
                  <ul className="categor-list">
                    <li>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Surface">Surface Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </li>
                  </ul>
                </div>
                {/*/ End Single Widget */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
