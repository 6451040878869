import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
       <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Contact Us</h2>
                <ul className="bread-list">
                  <li><Link to="/">Home</Link></li>
                  <li><i className="icofont-simple-right" /></li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="contact-us section">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.2768225293917!2d73.02876397196978!3d19.007519714764744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3075bf5c591%3A0x63aebebcd2cca5fe!2sPlot%20No%2047%2C%20Belle%20Vista%2C%20Sector%2015%2C%20CBD%20Belapur%2C%20Navi%20Mumbai%2C%20Maharashtra%20400614!5e0!3m2!1sen!2sin!4v1696067795694!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-us-form">
                  <h2>Contact With Us</h2>
                  <p>
                    If you have any questions please fell free to contact with
                    us.
                  </p>
                  {/* Form */}
                  <form
                    className="form"
                    id="contactForm"
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className=""
                            onChange={(event) => handleFnameChange(event)}
                            type="text"
                            id="fname"
                            name="fname"
                            value={fname}
                            placeholder="Enter your first name"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            className=""
                            onChange={(event) => handlelnameChange(event)}
                            type="text"
                            id="lname"
                            name="lname"
                            value={lname}
                            placeholder="Enter your last name"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className=""
                            onChange={(event) => handleemailChange(event)}
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Enter your Email"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>{" "}
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className=""
                            onChange={(event) => handlesubjectChange(event)}
                            type="text"
                            id="subject"
                            name="subject"
                            value={subject}
                            placeholder="Enter subject"
                            required
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className=""
                            id="message"
                            name="message"
                            rows={1}
                            placeholder="Enter Message"
                            defaultValue={""}
                            value={message}
                            onChange={(event) => handlemessageChange(event)}
                          />
                          <p class="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button
                          className="btn btn-primary py-3 px-5"
                          type="submit"
                          defaultValue=""
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  <h5 style={{ color: "red" }}>{result}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-ui-call" />
                  <div className="content">
                    <h3>{companynumber}</h3>
                  </div>
                </div>
              </div>
              {/*/End single-info */}
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont-google-map" />
                  <div className="content">
                    <p>{companyaddress}</p>
                    
                  </div>
                </div>
              </div>
              {/*/End single-info */}
              {/* single-info */}
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-envelope" />
                  <div className="content">
                    <p>{companyemail}</p>
                  </div>
                </div>
              </div>
              {/*/End single-info */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
