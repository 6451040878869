import { Outlet, Link } from "react-router-dom";
import Getquote from "../pages/Getquote";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = (
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
) => {
  return (
    <> <header className="header">
    {/* Topbar */}
    <div className="topbar">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-5 col-12">
            {/* Contact */}
            <ul className="top-link">
              <li><Link to="/About">About</Link></li>
              <li><Link to="/Terms">Terms</Link></li>
              <li><Link to="/Privacy">Privacy</Link></li>
              <li><Link to="/Contact">Contact</Link></li>
            </ul>
            {/* End Contact */}
          </div>
          <div className="col-lg-6 col-md-7 col-12">
            {/* Top Contact */}
            <ul className="top-contact">
              <li><i className="fa fa-phone" />{companynumber}</li>
              <li><i className="fa fa-envelope" />{companyemail}</li>
            </ul>
            {/* End Top Contact */}
          </div>
        </div>
      </div>
    </div>
    {/* End Topbar */}
    {/* Header Inner */}
    <div className="header-inner">
      <div className="container">
        <div className="inner">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12">
              {/* Start Logo */}
              <div className="logo">
                <Link to="/"><img src="img/logo.png" alt="#" style={{height:"80px"}} /></Link>
              </div>
              {/* End Logo */}
              {/* Mobile Nav */}
              <div className="mobile-nav" />
              {/* End Mobile Nav */}
            </div>
            <div className="col-lg-7 col-md-9 col-12">
              {/* Main Menu */}
              <div className="main-menu">
                <nav className="navigation">
                  <ul className="nav menu">
                    <li className=""><Link to="/">Home</Link>
                     
                    </li>
                    <li><Link to="/About">About Us</Link></li>
                    <li><Link to="#">Freight Services <i className="icofont-rounded-down" /></Link>
                      <ul className="dropdown">
                        <li><Link to="/Sea">Sea Freight Forwarding</Link></li>
                        <li><Link to="/Air">Air Freight Forwarding</Link></li>
                        <li><Link to="/Surface">Surface Transportation</Link></li>
                        <li><Link to="/Warehousing">Warehousing & Distribution</Link></li>
                        <li><Link to="/Multimodal">Multimodal Transportation</Link></li>
                        <li><Link to="/Hazardous">Hazardous Cargo</Link></li>
                      </ul>
                    </li>
                    <li><Link to="#">Trading Services<i className="icofont-rounded-down" /></Link>
                      <ul className="dropdown">
                        <li><Link to="/Trading">Agriculture </Link></li>
                        <li><Link to="/Trading">Building Materials </Link></li>
                        <li><Link to="/Trading">Consumer Goods </Link></li>
                        <li><Link to="/Trading">Intermodal </Link></li>
                        <li><Link to="/Trading">Paper </Link></li>
                        <li><Link to="/Trading">Raw Materials </Link></li>
                      </ul>
                    </li>
                    <li><Link to="/Contact">Contact Us</Link></li>
                  </ul>
                </nav>
              </div>
              {/*/ End Main Menu */}
            </div>
            <div className="col-lg-2 col-12">
              <div className="get-quote">
                <Link to="/Getquote" className="btn">Request Quote</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*/ End Header Inner */}
  </header>
    </>
  );
};

export default Header;
