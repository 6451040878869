import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Trading Services</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Trading Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Trading Services</h2>
                <img src="img/section-img.png" alt="#" />
                <p>
                  {" "}
                  Shipping Made Simple: Explore Our Range of Trading Services
                  Options
                </p>
              </div>
            </div>
          </div>
          <div className="row  pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Agriculture</h3>
                <p>
                  At the core of our operations is an unwavering commitment to
                  securely and reliably transport essential agricultural
                  commodities such as grain, sugar, and oilseeds to their
                  destinations. We prioritize quality and competence in every
                  aspect of our service, ensuring the utmost reliability. Our
                  dedication, refined experience, and extensive knowledge are
                  the foundations of our agricultural product transportation
                  services. We have implemented advanced security measures,
                  including sophisticated transportation and loading systems, to
                  enhance safety across the entire logistics chain. Whether it's
                  transportation, equipment supply, raw material provision, or
                  precise distribution of finished products, we offer customized
                  solutions for every need.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Agriculture.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
          </div>
          <div className="row pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Building Materials.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Building Materials</h3>
                <p>
                  We specialize in tailoring logistics solutions to meet the
                  diverse requirements of the construction industry. Whether
                  it's transporting materials to construction sites, supplying
                  construction material producers, or managing transportation
                  from excavation sites to landfills, our solutions seamlessly
                  integrate into construction operations. Our core strengths
                  encompass comprehensive expertise in consultation, planning,
                  processing, and execution. This expertise enables us to
                  provide a wide range of integrated logistics and
                  transportation services, including efficient and sustainable
                  logistics concepts for both the supply and removal of building
                  materials.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
          </div>
          <div className="row  pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Consumer Goods</h3>
                <p>
                  We are dedicated to freshness, timeliness, and
                  eco-friendliness in delivering various goods, including
                  foodstuffs, beverages, household appliances, pet food, and
                  furniture. Our commitment to ensuring seamless delivery to our
                  customers' warehouses is unwavering. Our capabilities are
                  demonstrated by impressive statistics, such as transporting
                  one million liters of beverages daily and moving two million
                  domestic appliances annually. These figures showcase our
                  expertise in meeting the unique logistics and transportation
                  needs of international food and beverage manufacturers, retail
                  chains, and household appliance manufacturers.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Consumer Goods.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
          </div>
          <div className="row pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Intermodal.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Intermodal</h3>
                <p>
                  Our expertise lies in seamlessly integrating various modes of
                  transport, including rail, road, water, and air, into a
                  unified transport chain. We excel in creating a harmonious
                  fusion of these advantages. Our unaccompanied combined
                  transport services provide a wide range of solutions for goods
                  of all types. The foundation of our system is built on rail
                  transport, bolstered by our high-frequency block train network
                  that links two strategically located terminals. The outcome is
                  an optimized, efficient, and adaptable intermodal transport
                  solution designed to meet a variety of transportation
                  requirements.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
          </div>
          <div className="row  pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Paper</h3>
                <p>
                  The paper industry embodies a patient quality, marked by the
                  precise coordination of its production processes. In parallel,
                  supplying raw materials to this industry necessitates a
                  steadfast and dependable logistics partner. Whether it's
                  printing paper, packaging paper, tissue paper, or cardboard,
                  the common factor is their reliance on natural resources for
                  production. This fundamental need highlights the critical role
                  of well-coordinated logistics operations. These operations are
                  essential for the seamless flow of raw materials to support
                  production and the efficient delivery of finished products to
                  their destinations.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Paper.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
          </div>
          <div className="row pt-4 mt-4">
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div
                className="choose-right"
                style={{ backgroundImage: "url(img/Raw Materials.jpg)" }}
              >
                <div className="video-image"></div>
              </div>
              {/* End Choose Rights */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Raw Materials</h3>
                <p>
                  Our commitment revolves around optimizing the supply of
                  primary and secondary raw materials to international
                  industries and the energy sector. We understand that the
                  timely provision of these materials is vital for various
                  sectors. As a result, we offer complete logistics solutions
                  designed to streamline the process and enhance efficiency,
                  ensuring the swift and effective delivery of these essential
                  raw materials to their intended destinations. Through our
                  dedication to flexible, innovative, and comprehensive
                  solutions, we contribute to the sustained supply of raw
                  materials that drive industries and the energy sector.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Why choose */}
      <Footer />
    </>
  );
}
