import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <footer id="footer" className="footer ">
        {/* Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer">
                  <h2>{companyname}</h2>
                  <p className="text-justify">
                    Starting from modest beginnings, we've evolved into a market
                    leader, serving as the preferred logistics partner for
                    various industries, managing their inbound and outbound
                    shipments effectively. Operating from our central office in
                    Mumbai, we provide a one-stop solution, enabling our clients
                    to focus on their core operations.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h2>Quick Links</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <ul>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            About Us
                          </Link>
                        </li>
                       
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Get Quote
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <ul>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Trading
                          </Link>
                        </li>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/Home">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Terms
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h2>Services Links</h2>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <ul>
                        <li>
                          <Link to="/Sea">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Sea Freight Forwarding
                          </Link>
                        </li>
                        <li>
                          <Link to="/Air">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Air Freight Forwarding
                          </Link>
                        </li>
                        <li>
                          <Link to="/Surface">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Surface Transportation
                          </Link>
                        </li>
                        <li>
                          <Link to="/Warehousing">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />{" "}
                            Warehousing & Distribution
                          </Link>
                        </li>
                        <li>
                          <Link to="/Multimodal">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />{" "}
                            Multimodal Transportation
                          </Link>
                        </li>
                        <li>
                          <Link to="/Hazardous">
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            />
                            Hazardous Cargo
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer">
                  <h2>Newsletter</h2>
                  <p>
                  Receive personalized newsletters tailored to your specific interests and needs within the freight forwarding industry.


                  </p>
                  <form
                  className="newsletter-inner"
                  action="/php/thankyou-subscribe.php "
                  method="POST"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <input
                    onChange={(event) => handleSubemailChange(event)}
                    id="subemail"
                    value={subemail}
                    className="common-input"
                    type="email"
                    name="subemail"
                    placeholder="Enter your email "
                    required
                  />
                    <button
                      type="button"
                      className="button"
                    >
                      <i className="icofont icofont-paper-plane" />
                    </button>
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/ End Footer Top */}
        {/* Copyright */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="copyright-content">
                  <p>
                    © Copyright 2023 {companyname} | All Rights Reserved by{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*/ End Copyright */}
      </footer>
    </>
  );
};

export default Footer;
