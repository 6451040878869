import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
          Our Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Our Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Solutions</h1>
          </div>

          <div className="row g-4 portfolio-container">
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Air Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Air Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Air Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Air"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Sea Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Sea Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Sea Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Sea"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Road Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Road Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Road Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Road"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Rail Freight.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Rail Freight</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Rail Freight</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Rail"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img className="img-fluid w-100" src="img/Courier.jpg" alt="" />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Courier</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Courier</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Courier"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 portfolio-item first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="portfolio-inner">
                <img
                  className="img-fluid w-100"
                  src="img/Warehousing Services.jpg"
                  alt=""
                />
                <div className="text-center p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-0">Warehousing Services</h5>
                </div>
                <div className="portfolio-text text-center bg-white p-4">
                  <p className="text-primary mb-2">Services</p>
                  <h5 className="lh-base mb-3">Warehousing Services</h5>
                  <div className="d-flex justify-content-center">
                    <Link
                      className="btn btn-square btn-primary rounded-circle mx-1"
                      to="/Warehousing"
                      data-lightbox="portfolio"
                    >
                      <i className="fa fa-eye" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Team Start */}
            <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="bg-primary mb-3 mx-auto"
              style={{ width: "60px", height: "2px" }}
            />
            <h1 className="display-5 mb-5">Our Industries</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Automotive</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/Consumer Goods.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Consumer Goods</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Mineral Oil.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Mineral Oil</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Wood.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Wood</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img className="img-fluid" src="img/Chemicals.jpg" alt="" />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Chemicals</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="overflow-hidden position-relative">
                  <img
                    className="img-fluid"
                    src="img/Raw Materials.jpg"
                    alt=""
                  />
                  <div className="team-social">
                    <Link
                      to="/Industries"
                      className="btn btn-primary rounded-pill py-3 px-5"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4">
                  <h5 className="mb-0">Raw Materials</h5>
                  <span className="text-primary">Industries</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}
      <Footer />
    </>
  );
}
