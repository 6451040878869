import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Air Freight Forwarding</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Air Freight Forwarding</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="news-single section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="single-main">
                    {/* News Head */}
                    <div className="news-head">
                      <img src="img/Air Freight Forwarding.jpg" alt="#" />
                    </div>
                    {/* News Title */}
                    <h1 className="news-title">Air Freight Forwarding</h1>
                    {/* Meta */}

                    {/* News Text */}
                    <div className="news-text">
                      <p>
                        Through strategic partnerships with major airline
                        conglomerates across key trade routes, {companyname} ensures you benefit from competitive rates,
                        predictable transit times, secured allocations, and
                        consistent carrier capacity. Our unique strengths in air
                        freight solutions, dedicated account managers, and
                        round-the-clock service assurance contribute to the
                        success of your business.
                      </p>

                      <p>Highlighted features include:</p>

                      <ul>
                        <li>
                          Pick-up of cargo from the consignee's warehouse.
                        </li>
                        <li>Expedited and vendor management services.</li>
                        <li>
                          Clearance of imported cargo at the port of discharge.
                        </li>
                        <li>In-house export packing facilities.</li>
                        <li>Comprehensive documentation services.</li>
                        <li>
                          Safe handling of general cargo and perishable goods.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="main-sidebar">
                {/* Single Widget */}
                <div className="single-widget category">
                  <h3 className="title">Other Services</h3>
                  <ul className="categor-list">
                    <li>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Surface">Surface Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </li>
                  </ul>
                </div>
                {/*/ End Single Widget */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
