import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="container-fluid page-header py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            Courier
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Courier
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="blog-post-main">
        <div className="blog-content-inf py-5">
          <div className="container py-md-5 py-4">
            <div
              className="blog-posthny-info mx-auto"
              style={{ maxWidth: "800px" }}
            >
              <div className="style-title position-relative text-center">
                <div
                  className="bg-primary mb-3 mx-auto"
                  style={{ width: "60px", height: "2px" }}
                />
                <h1 className="display-5 mb-4">Courier</h1>
              </div>
              <div className="single-post-image mb-4">
                <img
                  src="img/Courier.jpg"
                  className="img-fluid radius-image"
                  alt="blog-post-image"
                />
              </div>
              <div className="single-post-content">
                <p className="mb-4">
                  <p>
                    {companyname}, a well-established logistics company, has
                    expanded its range of services to include the courier
                    industry through its subsidiary, {companyname}. Committed to
                    providing reliable and efficient courier services,{" "}
                    {companyname} sets itself apart in the sector by catering to
                    the B2B needs of businesses of all sizes.
                  </p>

                  <p>
                    Recognizing that each industry has unique requirements for
                    courier services, {companyname} offers specialized courier
                    solutions tailored to meet those demands. The team at{" "}
                    {companyname} is dedicated to ensuring timely, considerate,
                    and accurate delivery of shipments.
                  </p>

                  <p>
                    In today's fast-paced world, the courier industry has gained
                    significant importance, driven by the rise of e-commerce and
                    the demand for swift and efficient delivery services.{" "}
                    {companyname} prioritizes fast, dependable, and
                    cost-effective courier services to meet the needs of its
                    customers, whether it involves documents or large goods. The
                    company has the expertise and capabilities to handle various
                    types of transportation.
                  </p>

                  <p>
                    While specializing in the transportation of diverse
                    commodities, {companyname} maintains a customer-centric
                    approach and has built a robust network in this competitive
                    sector. Its commitment to providing reliable and efficient
                    courier services has earned the company a strong reputation.
                  </p>

                  <p>
                    From e-commerce to enterprise, we deliver a comprehensive
                    range of services, including express parcel transportation
                    and cross-border solutions.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
