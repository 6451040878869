import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };

  const handlesubjectChange = (e) => {};

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Slider Area */}
      <section className="slider w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4"></source>
        </video>
      </section>
      {/*/ End Slider Area */}
      {/* Start Schedule Area */}
      <section className="schedule">
        <div className="container">
          <div className="schedule-inner">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 ">
                {/* single-schedule */}
                <div className="single-schedule first">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-bullseye" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Mission</h4>
                      <p>
                        To provide seamless global logistics solutions to your
                        business, connecting businesses and markets with
                        efficiency and reliability.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                {/* single-schedule */}
                <div className="single-schedule middle">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-eye" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Vision</h4>
                      <p>
                        To be the industry leader, setting new standards in
                        freight forwarding through innovation and excellence,
                        while prioritizing customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-12">
                {/* single-schedule */}
                <div className="single-schedule last">
                  <div className="inner">
                    <div className="icon">
                      <i className="fa fa-check" />
                    </div>
                    <div className="single-content">
                      <span></span>
                      <h4>Our Value</h4>
                      <p>
                        Our values of innovation and sustainability guide us in
                        providing top-notch logistics solutions that benefit
                        both our clients and the environment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/End Start schedule Area */}

      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Navigating Trade Routes, Expanding Opportunities</h2>
                <img src="img/section-img.png" alt="#" />
                <p>Welcome to {companyname}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              {/* Start Choose Left */}
              <div className="choose-left">
                <h3>Who We Are</h3>
                <p>
                  Starting from modest beginnings, we've evolved into a market
                  leader, serving as the preferred logistics partner for various
                  industries, managing their inbound and outbound shipments
                  effectively.
                </p>

                <p>
                  Operating from our central office in Mumbai, we provide a
                  one-stop solution, enabling our clients to focus on their core
                  operations.
                </p>

                <p>
                  We offer a diverse range of hassle-free services tailored to
                  our clients' needs, including airfreight, seafreight,
                  warehousing, customs clearance, and transportation.
                  Additionally, we provide value-added services like cargo
                  insurance, packing, fumigation, palletization, and more, as
                  required.
                </p>
              </div>
              {/* End Choose Left */}
            </div>
            <div className="col-lg-6 col-12">
              {/* Start Choose Rights */}
              <div className="choose-right">
                <div className="video-image">
                  {/* Video Animation */}

                  {/*/ End Video Animation */}
                  <Link to="/About" className=" btn mfp-iframe">
                    Learn More
                  </Link>
                </div>
              </div>
              {/* End Choose Rights */}
            </div>
          </div>
        </div>
      </section>
      {/*/ End Why choose */}




      {/* Start Blog Area */}
      <section className="blog section" id="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Our Freight Solutions</h2>
                <img src="img/section-img.png" alt="#" />
                <p>
                  Simplify Your Cargo Movement with Our Expert Freight
                  Forwarding Services
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Sea Freight Forwarding.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </h2>
                    <p className="text">
                      {companyname} has the capability to manage delicate cargo,
                      unconventional dimensions, and remote destinations.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Air Freight Forwarding.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </h2>
                    <p className="text">
                      Through strategic partnerships with major airline
                      conglomerates across key trade routes, {companyname}{" "}
                      ensures
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Surface Transportation.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Surface">Surface Transportation</Link>
                    </h2>
                    <p className="text">
                      {" "}
                      Our land transportation services are precisely tailored to
                      meet our clients' needs, ensuring secure and...
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Warehousing & Distribution.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </h2>
                    <p className="text">
                      {" "}
                      {companyname} provides comprehensive warehousing
                      solutions, backed by well-trained personnel and
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Multimodal Transportation.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </h2>
                    <p className="text">
                      {" "}
                      When a single organization handles your transportation
                      needs, your company can achieve significant time and cost
                      savings.
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              {/* Single Blog */}
              <div className="single-news">
                <div className="news-head">
                  <img src="img/Hazardous Cargo.jpg" alt="#" />
                </div>
                <div className="news-body">
                  <div className="news-content">
                    <div className="date">Services</div>
                    <h2>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </h2>
                    <p className="text">
                      {companyname} excels in the comprehensive management of
                      hazardous and high-risk cargo logistics.{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Blog */}
            </div>
          </div>
        </div>
      </section>
      {/* End Blog Area */}

      {/* Start Appointment */}
      <section className="appointment">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Are Always Ready to Help You. Request A Quote</h2>
                <img src="img/section-img.png" alt="#" />
                <p></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <form
                className="form"
                action="/php/getquoteHome.php"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-12 col-sm-12 form-group">
                    <input
                      className=""
                      value={uname}
                      onChange={(event) => handleUnameChange(event)}
                      type="text"
                      name="uname"
                      id="uname"
                      placeholder="Enter your Fullname"
                      required="Please Enter the Fullname"
                    />{" "}
                  </div>
                  <div className="col-12 col-sm-12 form-group">
                    <input
                      className=""
                      value={uemail}
                      onChange={(event) => handleUemailChange(event)}
                      type="email"
                      name="uemail"
                      id="uemail"
                      placeholder="Enter Your Email"
                      required="Please Enter Your Email"
                    />{" "}
                  </div>
                  <div className="col-12 col-sm-12 form-group">
                    <input
                      className=""
                      value={uphone}
                      onChange={(event) => handleUphoneChange(event)}
                      type="text"
                      name="uphone"
                      id="uphone"
                      placeholder="Enter Phone Number"
                      required="Please Enter Phone Number"
                    />
                  </div>
                  <div className="col-12 col-sm-12 form-group">
                    <select
                      className="form-select border-0 px-4"
                      name="uservice"
                      id="uservice"
                      value={uservice}
                      onChange={(event) => handleuserviceChange(event)}
                    >
                      <option selected>Service Required</option>
                      <option value="Air Freight Forwarding">
                        Air Freight Forwarding
                      </option>
                      <option value="Ocean Freight Forwarding">
                        Sea Freight Forwarding
                      </option>
                      <option value="Land Freight Forwarding">
                        Land Freight Forwarding
                      </option>
                    </select>
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Get A Free Quote
                    </button>
                  </div>
                </div>
              </form>
              <h5 style={{ color: "red" }}>{result}</h5>
            </div>
            <div className="col-lg-6 col-md-12 ">
              <div className="appointment-image">
                <img src="img/contact-img.png" alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Appointment */}
            {/* Start portfolio */}
            <section className="portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Trading Services</h2>
                <img src="img/section-img.png" alt="#" />
                <p>
                  Shipping Made Simple: Explore Our Range of Trading Services
                  Options
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Agriculture.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Agriculture
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Building Materials.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Building Materials
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Consumer Goods.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Consumer Goods
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pt-4 ">
          <div className="row">
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Intermodal.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Intermodal
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Paper.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Paper
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className=" portfolio-slider">
                <div className="single-pf">
                  <img src="img/Raw Materials.jpg" alt="#" />

                  <Link to="/Trading" className="btn">
                    Raw Materials
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/ End portfolio */}
      {/* Start service */}

      <Footer />
    </>
  );
}
