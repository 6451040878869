import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="breadcrumbs">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Surface Transportation</h2>
                <ul className="bread-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="icofont-simple-right" />
                  </li>
                  <li className="active">Surface Transportation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="news-single section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="single-main">
                    {/* News Head */}
                    <div className="news-head">
                      <img src="img/Surface Transportation.jpg" alt="#" />
                    </div>
                    {/* News Title */}
                    <h1 className="news-title">Surface Transportation</h1>
                    {/* Meta */}

                    {/* News Text */}
                    <div className="news-text">
                      <p>
                        Our land transportation services are precisely tailored
                        to meet our clients' needs, ensuring secure and
                        predictable budgeting while efficiently managing the
                        transit schedules of your goods.
                      </p>

                      <p>
                        Guided by a commitment to care and perfection, we
                        prioritize the complete safety of your products during
                        transportation, utilizing our dedicated fleet of
                        containers and trailers. Our services are known for
                        their swiftness and reliability, aimed at delivering
                        items in impeccable condition.
                      </p>

                      <p>
                        With a strong focus on the safety of your cargo, we take
                        pride in our customer-centric approach, putting your
                        needs first.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="main-sidebar">
                {/* Single Widget */}
                <div className="single-widget category">
                  <h3 className="title">Other Services</h3>
                  <ul className="categor-list">
                    <li>
                      <Link to="/Sea">Sea Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Air">Air Freight Forwarding</Link>
                    </li>
                    <li>
                      <Link to="/Surface">Surface Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </li>
                    <li>
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </li>
                    <li>
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </li>
                  </ul>
                </div>
                {/*/ End Single Widget */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
